<template>
    <v-form>
        <v-container v-if="isAuth">
            <v-row class="mt-4" justify="center">
                <v-col cols="12" md="6">
                    <v-btn block @click="goBack" color="secondary">
                        Terug
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center">
                <div class="display-1 ml-2 mt-4">
                    Klant
                </div>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                        id="input-voornaam"
                        outlined
                        label="Voornaam"
                        v-model="form.voornaam"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="4" md="1">
                    <v-text-field
                        id="input-tussenvoegsel"
                        outlined
                        label="Tussenvoegsel"
                        v-model="form.tussenvoegsel"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="8" md="5">
                    <v-text-field
                        id="input-achternaam"
                        outlined
                        label="Achternaam"
                        v-model="form.achternaam"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-text-field id="input-email" outlined label="Email" v-model="form.email" required></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                        id="input-wachtwoord"
                        outlined
                        label="Wachtwoord"
                        v-model="form.password"
                        type="password"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="2">
                    <v-text-field
                        id="input-postcode"
                        outlined
                        label="Postcode"
                        v-model="form.postcode"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        id="input-plaats"
                        outlined
                        label="Plaats"
                        v-model="form.plaats"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="4">
                    <v-text-field
                        id="input-straat"
                        outlined
                        label="Straat"
                        v-model="form.straat"
                        required
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                    <v-text-field
                        id="input-nummer"
                        outlined
                        label="Nummer"
                        v-model="form.nummer"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-text-field
                        id="input-bsn"
                        outlined
                        label="BSN nummer"
                        v-model="form.bsn_nummer"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <span class="title">
                        Handtekening
                    </span>
                    <v-btn color="primary" @click="$refs['fotoUploader'].click()" block>
                        <v-icon> mdi-cloud-upload-outline </v-icon>&nbsp; Upload foto
                    </v-btn>
                    <input
                        id="fileinput-foto"
                        ref="fotoUploader"
                        type="file"
                        accept="image/*"
                        multiple
                        class="d-none"
                        @change="onPhotoUpload($event)"
                    />
                </v-col>
            </v-row>
            <transition name="slide-fade" mode="out-in">
                <v-row v-if="form.image" justify="center">
                    <v-col cols="12" md="6">
                        <v-img :key="form.image" :src="form.image"></v-img>
                    </v-col>
                </v-row>
            </transition>
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <span class="title">Klanttype</span>
                    <v-radio-group v-model="form.klant_type" row>
                        <v-radio label="Particulier" value="particulier"> </v-radio>
                        <v-radio label="Ondernemer" value="ondernemer"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row justify="center" v-if="form.klant_type === 'ondernemer'">
                <v-col cols="12" md="6">
                    <v-text-field
                        id="input-onderneming-naam"
                        outlined
                        label="Naam onderneming"
                        v-model="form.onderneming_naam"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" md="6">
                    <v-btn id="send-form-button" block color="success" class="ma-2" @click="sendForm">
                        {{ form._id ? 'Aanpassen' : 'Aanmaken' }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-container v-else>
            <v-row justify="center" align="center" style="height: 80vh;">
                <v-col cols="6">
                    <span class="display-2">
                        Dit is niet uw gebruiker.
                    </span>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script lang="ts">
import axios from '../../axios';

export default {
    data() {
        return {
            form: {
                klant_type: 'particulier'
            },
            isAuth: true
        };
    },
    async mounted() {
        if (this.$route.params.id) {
            try {
                const id = this.$route.params.id;
                this.form = { ...(await axios.get(`/users/${id}`)).data, password: '' };
                if (this.form.image) {
                    this.form.image = `${axios.defaults.baseURL}/${this.form.image}`;
                }
            } catch (error) {
                this.isAuth = false;
            }
        }
    },
    methods: {
        async sendForm() {
            try {
                if (!this.validateForm()) {
                    alert('Vul alle velden in');
                    return;
                }
                if (this.form.bsn_nummer.length !== 9) {
                    alert('BSN nummer moet 9 cijfers bevatten');
                    return;
                }

                const { voornaam, achternaam, tussenvoegsel } = this.form;
                let naam;
                if (this.form.klant_type === 'ondernemer') {
                    naam = this.form.onderneming_naam;
                } else {
                    naam = tussenvoegsel ? `${voornaam} ${tussenvoegsel} ${achternaam}` : `${voornaam} ${achternaam}`;
                }
                if (this.form._id) {
                    const postData = { ...this.form, naam };
                    delete postData._id;

                    await axios.put(`/users/${this.form._id}`, postData);
                } else {
                    await axios.post('/users', { ...this.form, naam });
                }

                this.$router.replace('/klanten');
            } catch (error) {
                console.error(error);
                alert('Heeft u alles correct ingevuld?');
            }
        },
        goBack() {
            this.$router.push('/klanten');
        },
        async onPhotoUpload(event) {
            const foto = event.target.files[0];

            this.form.image = await new Promise(resolve => {
                const FR = new FileReader();

                FR.onload = async e => {
                    resolve(e.target.result);
                };

                FR.readAsDataURL(foto);
            });
        },
        validateForm() {
            if (
                this.form.voornaam &&
                this.form.achternaam &&
                this.form.postcode &&
                this.form.plaats &&
                this.form.straat &&
                this.form.nummer &&
                this.form.bsn_nummer
            ) {
                return true;
            } else {
                return false;
            }
        }
    },
    watch: {
        'form.klant_type'() {
            if (this.form.klant_type === 'particulier') {
                this.form.onderneming_naam = '';
            }
        }
    },
    metaInfo: {
        title: 'Klant formulier'
    }
};
</script>
